














import { Component, Prop, Vue } from "vue-property-decorator";

@Component
export default class About extends Vue {
  p1 = `We create stunning apps that free humans up to do the things that humans are best at.`;
  p2 = `We believe that humans are the most valuable asset on the planet. But our time is often taken up with menial, repetitive tasks that are better left to machines.`;
  p3 = `We build apps and services that that handle those tasks for you, improving your efficiency and reducing your cognitive load.`;
  p4 = `So go build, create, connect and do whatever else it is that only you can do. Our apps will handle the rest.`;
}

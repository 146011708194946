


























import { Component, Prop, Vue } from "vue-property-decorator";

@Component
export default class Products extends Vue {
  worldClock = {
    id: 1,
    name: "World Clock",
    subName: "A timezone app for Zendesk",
    // imagePath: require("../assets/test.png"),
    logoPath: require("../assets/logo.svg"),
    logoAlt: "World Clock Logo. Globe with Clock inside it.",
    shortDescription:
      "World Clock lets you instantly see what timezone your customers are in.",
    longDescription:
      "If you have agents in multiple timezones, World Clock can show you those timezones and even show your agents what location is closest to your customer. This makes it easy to figure out exactly who should take a support request.",
    link: "https://www.zendesk.com/apps/support/world-clock-a-timezone-app/",
    linkText: "Get the App"
  };
}

import Vue from 'vue';
import VueRouter from 'vue-router';
import Home from '../views/Home.vue';

Vue.use(VueRouter);

const routes = [
  {
    path: '/',
    name: 'Home',
    component: Home,
    meta: {
      title: 'Home | CCC',
    },
  },
  {
    path: '/privacy',
    name: 'Privacy',
    meta: {
      title: 'Privacy | CCC',
    },
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () =>
      import(/* webpackChunkName: "about" */ '../views/Privacy.vue'),
  },
  {
    path: '/world-clock/terms',
    name: 'Terms',
    meta: {
      title: 'Terms and Conditions | CCC',
    },
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () =>
      import(/* webpackChunkName: "about" */ '../views/Terms.vue'),
  },
  {
    path: '/team-status/terms',
    name: 'Terms2',
    meta: {
      title: 'Terms and Conditions | CCC',
    },
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () =>
      import(/* webpackChunkName: "about" */ '../views/Terms-2.vue'),
  },
  {
    path: '/products/:id',
    name: 'Products',
    props: true,
    meta: {
      title: 'Products | CCC',
    },
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () =>
      import(/* webpackChunkName: "about" */ '../views/Product.vue'),
  },
  {
    path: '/about',
    name: 'About',
    meta: {
      title: 'About | CCC',
    },
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () =>
      import(/* webpackChunkName: "about" */ '../views/About.vue'),
  },
];

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  scrollBehavior(to, from, savedPosition) {
    return { x: 0, y: 0 };
  },
  routes,
});

export default router;

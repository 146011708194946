












// @ is an alias to /src
import { Component, Vue } from "vue-property-decorator";
import Header from "@/components/Header.vue";
import FooterHero from "@/components/FooterHero.vue";
import Products from "@/components/Products.vue";
import About from "@/components/About.vue";

@Component({ components: { FooterHero, Products, About, Header } })
export default class Home extends Vue {
  options = {
    root: null,
    rootMargin: "-75px -75px -75px -75px",
    threshold: 0
  };
  observer = new IntersectionObserver(function(entries, observer) {
    entries.forEach(entry => {
      if (entry.isIntersecting) {
        entry.target.classList.remove("hidden-text");
        document.getElementById("small-text")?.classList.remove("hidden-text");
      } else {
        entry.target.classList.add("hidden-text");
        document.getElementById("small-text")?.classList.add("hidden-text");
      }
    });
  }, this.options);

  mounted() {
    const targets = document.querySelectorAll(".header-text");
    targets.forEach(target => {
      this.observer.observe(target);
    });
  }
}










// @ is an alias to /src
import { Component, Vue, Watch } from "vue-property-decorator";

@Component({})
export default class App extends Vue {
  options = {
    root: null,
    rootMargin: "-50px -90px -75px -75px",
    threshold: 0
  };
  observer = new IntersectionObserver(function(entries, observer) {
    entries.forEach(entry => {
      if (!entry.isIntersecting) {
        entry.target.classList.remove("appear");
      } else {
        entry.target.classList.add("appear");
      }
    });
  }, this.options);

  mounted() {
    const targets = document.querySelectorAll(".fade-in");
    targets.forEach(target => {
      this.observer.observe(target);
    });
  }

  @Watch("$route", { immediate: true, deep: true })
  onRouteChange(value: any, oldValue: any) {
    document.title = value.meta.title;
  }
}
